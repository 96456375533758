import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./styles/theme";
import App from './App';
import ErrorComponent from "./components/Error";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionType } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import Loading from './components/Loading';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const rootEl = document.getElementById('root') as HTMLElement
const reactRoot = ReactDOM.createRoot(rootEl)

reactRoot.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate 
      interactionType={InteractionType.Redirect} 
      authenticationRequest={loginRequest} 
      errorComponent={ErrorComponent} 
      loadingComponent={Loading}
    >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);
