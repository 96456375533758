import Loading from '../components/Loading';
import {  RequestStatus } from '../types';
import { getAllUsers } from '../api'
import { useWithData } from '../hooks';
import Games from '../games';
import { Typography } from '@mui/material';
import Container from '../components/Container';


const GuessFromAll = () => {
  const [users, requestState] = useWithData(getAllUsers)

  if(requestState !== RequestStatus.SUCCESS || !users) {
    return (<Loading />)
  }

  return (
    <Container>
      <Typography variant='h1' sx={{fontSize: 20, marginBottom: '.5rem'}}>Alle</Typography>
      <Games users={users}/>
    </Container>
  )
}

export default GuessFromAll
