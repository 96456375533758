import React, { useState } from 'react'
import { Button, ButtonGroup, Typography } from '@mui/material'
import Photo from '../../components/Photo'
import { IUser } from '../../types';
import Container from '../../components/Container';

interface IProps {
  next: (userId: string | null) => void;
  correctUser: IUser;
  users: IUser[];
  skip: (userId: string) => void;
}

const showButtons = (
  users: IUser[],
  currentGuess: string | null,
  guess: (id: string) => void,
  correctUserId: string,
  skip: (guess: string) => void
  ) => {

  const guessUser = (newGuess: string) => {
    if(!currentGuess) {
      guess(newGuess)
    }
  }

  const setButtonVariantAndColor = (correctUserId: string, buttonUser: string, currentGuess: string | null): ['contained' | 'outlined', 'error' | 'success' | 'primary'] => {
    if(!!currentGuess) {
      if(buttonUser === correctUserId) {
        return ['contained', 'success']
      } else if (buttonUser === currentGuess) {
        return ['contained', 'error']
      } else {
        return ['outlined', 'primary']
      }
    } else {
      return ['outlined', 'primary']
    }
  }

  return users.map((user) => {
    const [variant, color] = setButtonVariantAndColor(correctUserId, user.mail, currentGuess)
    return(
      <Button
        key={user.mail}
        onClick={() => guessUser(user.mail)}
        color={color}
        variant={variant}
        style={{border: 'none'}}
        >
          <Photo email={user.mail} skip={() => skip(user.mail)} isButton/>
        </Button>
      )
    }
  )
}

const GuessPhoto = ({ users, correctUser, next, skip }: IProps) => {
  const [myGuess, setMyGuess] = useState<string | null>(null)
  const buttons = showButtons(users, myGuess, setMyGuess, correctUser.mail, skip)
  const goToNext = () => {
    next(myGuess)
  }

  return (
    <Container>
      <Typography variant='h2' fontSize={32}>{correctUser.displayName}</Typography>
      <div>
        <Button color="secondary" variant="contained" onClick={goToNext}>Neste</Button>
      </div>
      <ButtonGroup
        orientation="vertical"
        color="primary"
        aria-label="vertical outlined primary button group"
        style={{padding: '1em 0 1em 0', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}
      >
      {buttons}
      </ButtonGroup>
      
    </Container>
  )
}

export default GuessPhoto
