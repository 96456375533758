import { createTheme, adaptV4Theme } from '@mui/material/styles';

// Create a theme instance.
export const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#DC143C'
    },
    background: {
      default: '#fff',
    },
  },
}));
