import { CircularProgress, LinearProgress } from '@mui/material'
import React from 'react'

const Loading = () => (
  <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "3em"}}>
    <CircularProgress />
  </div>
)

export default Loading
