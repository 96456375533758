import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useQuery(query?: string) {
    const [searchParam] = useSearchParams();

    return useMemo(() => {
        const value = query ? searchParam.get(query) : searchParam.get('game');
        return value || ''
    }, [searchParam]);
}