import { RequestStatus } from '../types';
import { getGroupEvents } from '../api'
import { useWithData } from '../hooks';
import Loading from '../components/Loading';
import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import Container from '../components/Container';

const ListMyEvents = () => {
  const [events, requestState] = useWithData(getGroupEvents('3a31f765-e138-4dd8-9765-0516b90c5c50'))

  if(requestState !== RequestStatus.SUCCESS || !events) {
    return <Loading />
  }

  return (
    <Container>
      <Typography variant='h1' fontSize={40}>Velg arrangement</Typography>
      <List sx={{width: '100%'}}>
        {events.map((e) => (
          <ListItem divider sx={{paddingLeft: 0}}>
            <ListItemButton href={`/event/${encodeURI(e.id)}`} key={e.id}>
              <ListItemText primary={e.subject} secondary={`${e.date} (${e.attendees.length})`} />
            </ListItemButton>
          </ListItem>
        )
        )}
      </List>
    </Container>
  )
}

export default ListMyEvents
