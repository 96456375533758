import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Skeleton, Stack } from '@mui/material';
import { useEffect, useState } from 'react'
import { graphConfig } from '../authConfig';
import { get } from '../utils/callMs'

interface IProps {
  email: string;
  skip: () => void;
  isButton?: boolean;
}

enum RequestStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  SHOULD_LOAD = 'SHOULD_LOAD',
  ERROR = 'ERROR'
}

const Photo = ({ email, skip, isButton }: IProps) => {
  const { inProgress } = useMsal();
  const [image, setImage] = useState<any>(null)
  const [imageRequest, setImageRequest] = useState<RequestStatus>(RequestStatus.SHOULD_LOAD)

  useEffect(() => {
      if ((imageRequest === RequestStatus.SHOULD_LOAD) && inProgress === InteractionStatus.None) {
          setImageRequest(RequestStatus.LOADING)
          get(graphConfig.profilePhoto(email))
            .then(response => {
              if(response.status === 404) {
                throw new Error('NOT_FOUND')
              }
              return response
            })
            .then(response => response?.blob())
            .then(response => {
              setImageRequest(RequestStatus.SUCCESS)
              setImage(URL.createObjectURL(response))
            })
            .catch(() => {
                setImageRequest(RequestStatus.ERROR)
            })
      }
  }, [inProgress, image, imageRequest, email]);

  useEffect(() => {
    if(imageRequest === RequestStatus.ERROR) {
      skip()
    }
  })

  const skeletonSize = isButton ? 280 : 320;
  return (
    <div style={{height: "20em", display: "flex"}}>
      {imageRequest === RequestStatus.SUCCESS && <img alt='neida du. ikke juks.' style={{height: "20em"}} src={image} />}
      {(imageRequest === RequestStatus.LOADING || imageRequest === RequestStatus.SHOULD_LOAD)
        && <div><Skeleton variant="rectangular" width={skeletonSize} height={skeletonSize} /></div>}
    </div>
  )
}

export default Photo;
