import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "d8101fb0-87dc-4a02-8c1c-04caf8907690",
        authority: "https://login.microsoftonline.com/4ffe5c78-bca8-4042-8306-6b480bdd46bd",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read", "GroupMember.Read.All"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    me: () => "https://graph.microsoft.com/v1.0/me",
    users: () => "https://graph.microsoft.com/v1.0/groups/3a31f765-e138-4dd8-9765-0516b90c5c50/members?$select=id,jobTitle,department,mail,displayName",
    profilePhoto: (email: string) => `https://graph.microsoft.com/v1.0/users/${email}/photo/$value`,
    myEvents: () => "https://graph.microsoft.com/v1.0/me/events?$filter=isCancelled eq false",
    myEvent: (id: string) => `https://graph.microsoft.com/v1.0/me/events/${id}`,
    groupEvents: (groupId: string) => `https://graph.microsoft.com/v1.0/groups/${groupId}/events?$filter=isCancelled eq false`,
    groupEvent: (groupId: string, eventId: string) => `https://graph.microsoft.com/v1.0/groups/${groupId}/events/${eventId}`
}
