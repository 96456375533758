import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

export async function get(endpoint: string) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const options = {
        method: "GET",
        headers: headers
    };

    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    return fetch(endpoint, options)
}
