import { ListItem, ListItemButton, ListItemText } from "@mui/material";

const MenuButton = ({target, children}: {children: string; target: string;}) => (
  <ListItem divider sx={{paddingLeft: 0}}>
    <ListItemButton href={target}>
      <ListItemText>{children}</ListItemText>
    </ListItemButton>
  </ListItem>
)
export default MenuButton

