import { useParams } from 'react-router-dom';
import { RequestStatus } from '../types';
import { getGroupEvent } from '../api'
import Loading from '../components/Loading';
import Games from '../games';
import { useWithData } from '../hooks';
import { Typography } from '@mui/material';
import Container from '../components/Container';

const GuessFromEvent = () => {
  let { eventId } = useParams();
  const [event, requestState] = useWithData(getGroupEvent('3a31f765-e138-4dd8-9765-0516b90c5c50', eventId ?? ''))

  if(requestState !== RequestStatus.SUCCESS || !event) {
    return <Loading />
  }
  return (
    <Container>
      <Typography variant='h1' sx={{fontSize: 20, marginBottom: '.5rem'}}>{event.subject}</Typography>
      <Games users={event.attendees} />
    </Container>
  )
}

export default GuessFromEvent;
