import React from "react";
import { Button, Typography } from "@mui/material";
import { shuffle } from "lodash/fp";
import GuessPhoto from "./GuessPhoto";
import { IUser } from "../../types";
import { getRandomUsers } from "../../utils/game-utils";
import Container from "../../components/Container";
import Streak from "../../components/Streak";
import Complete from "../../components/Complete";

interface IProps {
  users: IUser[]
}
const Guess: React.FC<IProps> = ({ users }) => {
  const [ignoredUsers, setIgnoredUsers] = React.useState<string[]>([])
  const [numberOfGuesses, setNumberOfGuesses] = React.useState<number>(0)
  const [streak, setStreak] = React.useState<number>(0);
  const [correct, setCorrect] = React.useState<number>(0);


  const [randomUsers, correctUser] = React.useMemo<[IUser[], IUser]>(() => {
    const nonIgnoredUsers = users.filter((user) => !ignoredUsers.includes(user.mail))
    return getRandomUsers(users, nonIgnoredUsers)
  }, [users, numberOfGuesses, ignoredUsers])

  if(ignoredUsers.length === users.length) {
    return (
      <Complete streak={streak} correct={correct} guesses={numberOfGuesses}/>
    )
  }

  const goToNextUser = (guessId: string | null) => {
    if(guessId === correctUser?.mail) {
      setNumberOfGuesses(numberOfGuesses + 1)
      setIgnoredUsers([guessId, ...ignoredUsers])
      setStreak(streak + 1);
      setCorrect(correct + 1)
    } else {
      setNumberOfGuesses(numberOfGuesses + 1)
      setStreak(0);
    }
  }
  const skipAndGotoNext = (guessId: string) => {
    setIgnoredUsers([guessId, ...ignoredUsers])
  }
  return (
    <Container>
      <Typography variant='h3' fontSize={32}>{ignoredUsers.length} / {users.length}</Typography>
      <div>
        <GuessPhoto
          key={numberOfGuesses}
          users={shuffle([...randomUsers, correctUser])}
          correctUser={correctUser}
          next={goToNextUser}
          skip={skipAndGotoNext}
          />
          <Streak streak={streak} />
      </div>
    </Container>
  )
}

export default Guess
