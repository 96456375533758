import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React from "react"
import { loginRequest } from "./authConfig";
import { RequestStatus } from "./types"

export const useWithData = <T>(getFunc: () => Promise<T>): [T | undefined, RequestStatus] => {
  const [data, setData] = React.useState<T>()
  const { instance } = useMsal();
  const [userRequestState, setUserRequestState] = React.useState<RequestStatus>(RequestStatus.SHOULD_LOAD)

  if(userRequestState === RequestStatus.SHOULD_LOAD) {
    setUserRequestState(RequestStatus.LOADING)
    getFunc().then((data) => {
      setUserRequestState(RequestStatus.SUCCESS)
      setData(data)
    }).catch((e) => {
        setData(undefined)
        setUserRequestState(RequestStatus.ERROR)
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount() as AccountInfo
          });
        }

      })
  }
  return [data, userRequestState]
}
