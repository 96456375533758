export interface IUser {
  mail: string;
  displayName: string;
}
export interface IDepartment {
  [key: string]: IUser[];
}

export interface IEvent {
  id: string;
  subject: string;
  attendees: IUser[];
  date: string;
}

export enum RequestStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  SHOULD_LOAD = 'SHOULD_LOAD',
  ERROR = 'ERROR'
}
