export interface IGameType {
    [key: string]: IGameProperty;
}
export enum GameName {
    GuessName = "guessname",
    GuessPhoto = "guessphoto"
} 
export interface IGameProperty {
    name: GameName, label: string
}
export const GameType2: IGameType = {
    guessphoto: {name: GameName.GuessPhoto, label: "Finn riktig bilde"},
    guessname: {name: GameName.GuessName, label: "Finn riktig navn"}
}
