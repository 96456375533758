import { List, Typography } from '@mui/material';
import { getAllDepartments } from '../../api';
import MenuButton from '../../components/MenuButton';
import Loading from '../../components/Loading';
import { useWithData } from '../../hooks';
import { IDepartment, RequestStatus } from '../../types';

const furlifyDepartment = (department: string) => `/department/${department?.toLocaleLowerCase().replaceAll(' ','-')}`;

const DepartmentsPage = () => {
  const [departments, requestState] = useWithData<IDepartment>(getAllDepartments)

  if(requestState !== RequestStatus.SUCCESS || !departments) {
    return (<Loading />)
  }

  return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
    <Typography variant='h1' fontSize={40}>Velg avdeling</Typography>
    <List sx={{width: '100%'}}>
      {Object.keys(departments)?.map(dept =>
        <MenuButton target={furlifyDepartment(dept)} key={dept}>
          {dept}
        </MenuButton>
      )}
      </List>
  </div>
}
export default DepartmentsPage;
