import React from 'react';
import { List, ListItem, Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RuleIcon from '@mui/icons-material/Rule';

const Complete = ({streak, guesses, correct}: {streak: number; guesses: number; correct: number}) => 
    <div>
        <Typography variant='h1' fontSize={24}>Gratulerer, du er gjennom alle!</Typography>
        <List>
            <ListItem><CheckIcon /><Typography sx={{marginLeft: '1rem'}}>Antall riktige: {correct}/{guesses} ({Math.floor(correct/guesses*100)}%)</Typography></ListItem>
            <ListItem><ClearIcon /><Typography sx={{marginLeft: '1rem'}}>Antall feil: {guesses-correct}</Typography></ListItem>
            <ListItem><RuleIcon /><Typography sx={{marginLeft: '1rem'}}>Beste streak: {streak}</Typography></ListItem>
        </List>
        <Button color="secondary" variant="contained" onClick={() => window.location.reload()}>Start på nytt</Button>

    </div>
export default Complete;