import { List, Typography } from "@mui/material"
import Container from "../components/Container"
import MenuButton from "../components/MenuButton"

export const Home = () => {
  return (
    <Container>
      <Typography variant='h1' fontSize={40}>Velg</Typography>
      <List sx={{width: '100%'}}>
        <MenuButton target="/all">Alle</MenuButton>
        <MenuButton target={"/department"}>Avdeling</MenuButton>
        <MenuButton target={"/event"}>Arrangement</MenuButton>
      </List>
    </Container>
  )
}

export default Home
