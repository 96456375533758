import React, { useEffect, useState } from 'react';
import { Snackbar, Slide } from '@mui/material';

const messages = ["Du er på en streak!🔥", "Dette er bra! 🔥🔥", "Nå er du rå! 🔥🔥🔥"]
const streakThreshold = 5;

const Streak = ({streak}: {streak: number}) => {
  const [message, setMessage] = useState<string>('')
  useEffect(() => {
    if (streak >= streakThreshold) {
      const messageIndex = Math.floor((streak / streakThreshold) - 1);
      setMessage(messages[messageIndex])
    }
  }, [streak])

  return <Snackbar
    open={streak >= streakThreshold && (streak % streakThreshold) === 0 && !!message}
    autoHideDuration={null}
    anchorOrigin={{
      horizontal: "right",
      vertical: "top"
    }}
    TransitionComponent={(props) => <Slide {...props} direction="left" />}
    message={message}
    />
}
export default Streak;
