import { List, Typography } from "@mui/material";
import Container from "../components/Container";
import ListItemElement from "../components/ListItemElement";
import { useQuery } from "../hooks/useQuery";
import { IUser } from "../types";
import { GameName, GameType2, IGameProperty } from "./gameTypes";
import GuessNameFromPhoto from "./guess-name-from-photo";
import GuessPhotoFromName from "./guess-photo-from-name";


const LoadGame = (gameType: IGameProperty, users: IUser[]) => {
    switch (gameType.name) {
      case GameName.GuessName: return <GuessNameFromPhoto users={users} />
      case GameName.GuessPhoto: return <GuessPhotoFromName users={users} />
    }
  }

const Games = ({ users }: {users: IUser[] }) => {
    const query = useQuery();
    const selectedGame = GameType2[query];
    if (selectedGame) {
        return LoadGame(selectedGame, users);
    }
  return (
    <Container>
      <List sx={{width: '100%'}}>
        {Object.keys(GameType2)?.map(game => 
            <ListItemElement image={GameType2[game].name} linkTo={`?game=${game}`} key={game}>
                <Typography variant="body1">{GameType2[game].label}</Typography>
            </ListItemElement>
        )}

      </List>
    </Container>
  )
}

export default Games
