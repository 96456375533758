import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getAllDepartments } from '../../api';
import Container from '../../components/Container';
import Loading from '../../components/Loading';
import Games from '../../games';
import { IUser, RequestStatus } from '../../types';


const GuessFromDepartment = () => {
  const location = useLocation();
  const { depId }  = useParams();
  const [userRequestState, setUserRequestState] = React.useState<RequestStatus>(RequestStatus.SHOULD_LOAD)
  const [users, setUsers] = useState<IUser[]>();
  const [department, setDepartment] = useState<string>();

  React.useEffect(() => {
    const hasLocationState = location.state?.department && location.state?.users;
    if (hasLocationState) {
      setDepartment(location.state.department);
      setUsers(location.state.users);
    }
    else if ((userRequestState === RequestStatus.SHOULD_LOAD) && depId && !hasLocationState) {
      setUserRequestState(RequestStatus.LOADING)

      getAllDepartments().then((res) => {
        const dept = Object.keys(res).filter(r => r.toLocaleLowerCase().replaceAll(' ', '-') === depId);
        if (dept.length > 0) {
          const filteredDepartment = dept[0];
          setDepartment(filteredDepartment);
          setUsers(res[filteredDepartment])
        }
        setUserRequestState(RequestStatus.SUCCESS)
      }).catch(() => {
          setUserRequestState(RequestStatus.ERROR)
        });
    }
  }, [setDepartment, userRequestState, location, depId]);
  if (!users) {
    return <Loading />
  }
  return (
    <Container>
      <Typography variant='h1' sx={{fontSize: 20, marginBottom: '.5rem'}}>{department}</Typography>
      <Games users={users} />
    </Container>
  )
}
export default GuessFromDepartment;
