import dayjs from 'dayjs';
import {  graphConfig } from './authConfig';
import { IDepartment, IEvent, IUser } from './types';
import { get } from './utils/callMs';

interface ODataUser {
  department: string;
  mail: string;
  id: string;
  jobTitle: string;
  displayName: string;
}
export const getAllUsers = () => new Promise<IUser[]>((resolve, reject) => {
  const getUsers = (nextUrl: string, users: IUser[] = []) => {
    if(!nextUrl) {
      const filteredUsers = users.filter(user => !!user.displayName)
      return resolve(filteredUsers)
    }

    get(nextUrl).then(resp => resp.json()).then((resp => {
      const nextLink = resp['@odata.nextLink']
      const allUsers: IUser[] = [...users, ...resp.value]
      getUsers(nextLink, allUsers)
    })).catch(e => reject(e))
  }

  getUsers(graphConfig.users())
})

export const getAllDepartments = () => new Promise<IDepartment>((resolve, reject) => {
  const getDepartments = (nextUrl: string, odata: ODataUser[] = []) => {
    if(!nextUrl) {
      const departments = odata.reduce((acc: IDepartment, cur) => {
        const user: IUser = {mail: cur.mail, displayName: cur.displayName};
        if (acc[cur.department]) {
          acc[cur.department].push(user);
        }
        else {
          acc[cur.department] = [user];
        }
        return acc;
      }, {});
      return resolve(departments)
    }

    get(nextUrl).then(resp => resp.json()).then((resp => {
      const nextLink = resp['@odata.nextLink']
      const res = resp.value as ODataUser[];

      const allOdata: ODataUser[] = [...odata, ...res];
      getDepartments(nextLink, allOdata)
    })).catch(e => reject(e))
  }

  getDepartments(graphConfig.users())
})

interface MSWrapper<T> {
  "@odata.context": string;
  "@odata.nextLink": string;
  value: T;
}
interface IEventResponseAttendee {
  emailAddress: {
    address: string;
    name: string;
  };
  status: {
    response: "none" | "tentativelyAccepted" | "accepted" | "declined";
    time: string
  };
}
interface IEventResponse {
  attendees: IEventResponseAttendee[]
  end: {
    dateTime: string;
    timeZone: string;
  };
  id: string;
  location: {
    displayName: string;
    locationType: string;
    uniqueId: string;
    uniqueIdType: string;
  };
  start: {
    dateTime: string;
    timeZone: string
  }
  subject: string;
}

const parseEvent = (event: IEventResponse): IEvent => {
  const parseUser = (reqAttendee: IEventResponseAttendee): IUser => ({
    displayName: reqAttendee.emailAddress.name,
    mail: reqAttendee.emailAddress.address
  })

  const attendees = event.attendees?.filter((att) => att.emailAddress.address.split('@')[1] === 'aztek.no')
    .filter((att) => ['accepted'].includes(att.status.response)).map(parseUser) ?? []
  const newEvent = {
    id: event.id,
    attendees: attendees,
    subject: event.subject,
    date: dayjs(event.start.dateTime).format('DD.MM.YYYY')
  }
  return newEvent
}

export const getMyEvents = () => new Promise<IEvent[]>((resolve, reject) => {
  get(graphConfig.myEvents()).then(resp => resp.json()).then((resp: MSWrapper<IEventResponse[]>) => {
    const events = resp.value.map(parseEvent).filter(e => e.attendees.length > 4)
    resolve(events)
  }).catch(reject)
})

export const getGroupEvents = (groupId: string) => () => new Promise<IEvent[]>((resolve, reject) => {
  get(graphConfig.groupEvents(groupId)).then(resp => resp.json()).then((resp: MSWrapper<IEventResponse[]>) => {
    const events = resp.value.map(parseEvent).filter(e => e.attendees.length > 4)
    resolve(events)
  }).catch(reject)
})

export const getGroupEvent = (groupId: string, eventId: string) => () => new Promise<IEvent>((resolve, reject) => {
  get(graphConfig.groupEvent(groupId, eventId)).then((resp) => resp.json()).then((resp) => {
    const event = parseEvent(resp)
    resolve(event)
  }).catch(reject)
})

export const getMyEvent = (eventId: string) => () => new Promise<IEvent>((resolve, reject) => {
  get(graphConfig.myEvent(eventId)).then((resp) => resp.json()).then((resp) => {
    const event = parseEvent(resp)
    resolve(event)
  }).catch(reject)
})
