import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './pages';
import DepartmentsPage from './pages/departments';
import GuessFromDepartment from './pages/departments/GuessFromDepartment';
import GuessFromAll from './pages/GuessFromAll'
import GuessFromEvent from './pages/GuessFromEvent';
import ListMyEvents from './pages/ListMyEvents';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="all" element={<GuessFromAll />} />
        <Route path='photo' element={<GuessFromAll />} />
        <Route path='department'>
          <Route index element={<DepartmentsPage />} />
          <Route path=":depId" element={<GuessFromDepartment />} />
        </Route>
        <Route path='event'>
          <Route index element={<ListMyEvents />} />
          <Route path=':eventId' element={<GuessFromEvent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
