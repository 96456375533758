import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemButton } from '@mui/material';
import { GameName } from '../games/gameTypes';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { To, useNavigate } from 'react-router-dom';


const ImageMap = (game: GameName) => {
  switch (game) {
    case GameName.GuessName: return <PersonSearchIcon />
    case GameName.GuessPhoto: return <ImageSearchIcon />
  }
}
const ListItemElement = ({linkTo, image, children}: {linkTo: To; image?: GameName; children: React.ReactNode}) => {
  const navigate = useNavigate();
  const onListItemClick = () => {
    navigate(linkTo);
  }
  return (
    <ListItem divider sx={{paddingLeft: 0}}>
      <ListItemButton onClick={onListItemClick}>
        {image && <ListItemAvatar>
          <Avatar>
            {ImageMap(image)}
          </Avatar>
        </ListItemAvatar>}
        {children}
      </ListItemButton>
    </ListItem>
  )
}
export default ListItemElement
